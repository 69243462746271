<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaSolicitacao } from '@/proxy-api/src/services/classes/Solicitacao/ConsultaSolicitacao';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormSolicitacao from '@/views/solicitacao/form/FormSolicitacao';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaSolicitacao(),
      formCadastro: FormSolicitacao,
    };
  },
};
</script>
